:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}
@font-face {
  font-family:'LikhithTlg045Unicode';
  src: url('../public/fonts/LikhithTlg045Unicode.eot');
src: url('../public/fonts/LikhithTlg045Unicode.eot?#iefix') format('embedded-opentype'),
  url('../public/fonts/LikhithTlg045Unicode.woff2') format('woff2'),
  url('../public/fonts/LikhithTlg045Unicode.woff') format('woff'),
  url('../public/fonts/LikhithTlg045Unicode.svg') format('svg');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  unicode-range: U+0020-00FE;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .fea-contact.trending-shows{    background: #fff;
  padding: 13px !important;} */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.div-grid-clss{display: grid;grid-template-columns: repeat(9, 1fr);justify-items: center;}
.epidos-add{    max-width: 134px;}

.form-control:focus{
  box-shadow: inherit!important;
  border-color: #fff!important;
}

.pad-left-80{
  padding-left: 80px!important;
}

.pad-right-80{
  padding-right: 80px!important;
}


.telufu a img{
  height: 40px;
  margin-right: 7px;
}
.telufu{display: flex;}


.telufu a{
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
} 

.telufu .etv.active{
background:rgba(168, 160, 176, 0.47);
}
/* .telufu .etv.active{
  background:rgba(0, 0, 0, 0.3);
  } */
.etv a:hover{color: #fff !important;}
.etv{
display: flex;
padding: 6px 12px;
border-left: 2px solid #26053D;
}
.cololink a{text-decoration: none;color: #fff;padding: 0.4rem 1.5rem;text-transform: uppercase;}

.cololink a:hover{
color: #fff;
}

.showed a:hover{
color: #000;
}

.slick-prev:before , .slick-next:before{display: none !important }
.title{
  padding: 0px 0px 10px 0px;
  overflow: auto;
  white-space: nowrap;
  margin: 0px;
}
.title li{
  display: inline-block;
  border: 1px solid #e2e2e2;
  padding: 3px 20px;
  margin: 0 16px 0px 0px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  line-height: 25px;
  text-align: center;
  font-weight: 700;
}

.react-tabs__tab--selected{background-color:#044afc;color: #fff;border-radius: 1px;padding: 10px;border: 1px solid #044afc !important;}
.head h3{top: 248px;
  left: 83px;
  width: 113px;
  height: 30px;
  /* UI Properties */
  text-align: left;
  font: normal normal bold 22px/30px Open Sans;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;}
  .weekd{padding: 0 0 40px 0;}
  .fordf{background: #ececec;
    padding: 0 0 40px 0;}
    .arc{background-color: #fff;}


.pageSpace{
  padding: 50px 0px;
  position: relative;
}

.backcolo {
  background-color: #fff;
  margin: 21px 0;
  border-radius: 10px;
  padding: 30px;
}
.div-grid{    display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: start;
  align-items: center;}


@media only screen and (max-width: 1620px) {
  .div-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: left;
    align-items: center;
}
  
  
}


@media only screen and (max-width: 1299px) {
  .pad-left-80{
    padding-left: 40px!important;
  }
  
  .pad-right-80{
    padding-right: 40px!important;
  }
}

@media only screen and (max-width: 1250px) {
  .div-grid-clss{display: grid;grid-template-columns: auto auto auto auto auto  auto auto;}
  .telufu a img{
    height: 28px;
  }
}

@media only screen and (max-width: 1162px) {
  .addtwostyl2 {
    height: 290px !important;
  }
 
  .etv{
    padding: 6px 6px;
  }
}

@media only screen and (max-width: 1080px) {
  .addtwostyl2 {
    height: 271px !important;
  }

  .telufu a img{
    height: 16px;
  }

  .etv{
    padding: 6px 10px;
  }

  .telufu a{
    font-size: 11px;
  }
}

@media only screen and (max-width: 991px) {
  .fortextc h4 {
    margin: 13px 0;
  
}
  .epidos-add{
    margin: 20px 10px !important;}

  .div-grid-clss{display: grid;grid-template-columns: auto auto auto auto auto  ;}
  .pading-top{margin: 0 0 10px 0;}
  .policits{padding: 10px !important;}
  .pad-left-80{
    padding-left: 1rem!important;
  }
  
  .pad-right-80{
    padding-right: 1rem!important;
  }
  .title li {
    margin: 0px 2px !important;
  }

  .telufu, .etv{
    display: inherit;
  }

  .etv{
    padding: 0px;
    border-left: 0px solid #26053D;
  }

  .telufu .etv a{
    background: transparent;
  }

  .mobile-menu .live-list a{
    background: transparent;
  }
  .showed a img {display: none;}
  .policits{padding: 0px;}
  .page-card table{width: 100% !important;}
  
}

@media only screen and (max-width: 767px) {



  .topnew h4 {
    
    font-size: 12px !important;
   
}




.fortextc h4 {
  margin: 10px 0 !important;
  
}
.fortextc h4 {
  margin: 31px 0;
  font-weight: 500;
  font-family: 'Noto Sans Telugu', sans-serif;
  font-size: 12px !important;
}
.newsLinks ul li a {
 
  font-size: 12px !important;}
  .date a h4 {
    
    font-size: 12px !important;}
    .extrasty a h4 {
      
      font-size: 12px !important;}
      .carousel-card h4 {
        font-size: 12px !important;
     
    }


  .headtile h4 {
    font-weight: 700;
    font-size: 15px;
    font-family: 'Noto Sans Telugu', sans-serif;
}
  .row-cols-auto{justify-content: center;}
  .div-grid-clss{display: grid;grid-template-columns: auto auto auto auto;}
.moaday li{margin:0 1px !important ;}

.latestNews .pad-left-80{
  padding-left: 0rem!important;
}

.latestNews .pad-right-80{
  padding-right: 0rem!important;
}
.ali {
  color: #044AFC;
  font-weight: 700;
  font-size: 14px !important;
  padding: 0 0 6px 0;
}
.sara > div{padding: 0px;}
.backcolo-in{padding: 10px 0 0 0 !important;}
}
@media screen and (max-width: 567px){
  .div-grid-clss{display: grid;grid-template-columns: auto auto auto ;}
.chanel-categories {

    top: 50px !important;}
 
}
@media screen and (max-width: 423px){
  .div-grid-clss{display: grid;grid-template-columns: auto auto ;}
.chanel-categories {

    top: 50px !important;
 
}

}
.iconcame{position: absolute !important;
  bottom: -11px !important;right: 10px !important;}
  .iconcame p{padding: 0px !important;}
  
  /* .epidos{padding: 20px 0;} */
.active{background: rgba(0, 0, 0, 0.3);}
  .epidos{    background-color: #fff;
    padding: 9px;
    border-radius: 4px;margin: 20px 0 !important;}
    .alledid{background-color: #f0f0f0;}
    .paddin-25{padding: 20px 0;}
    .headd{color: red !important;}
    .newsf{position: relative;}
    .headingfon{    color: #0c0437 !important;
      font-size: 24px !important;
      font-weight: 700 !important;
      font-family: 'Noto Sans Telugu', sans-serif;}
    .des p{ font-family: 'Noto Sans Telugu', sans-serif;font-size: 16px;}
    .fontfamild h4{font-family: 'Noto Sans Telugu', sans-serif;font-size: 16px !important; font-weight: 500 !important;}
    
.feature{
  background-color: #fff;
  border-radius: 5px;
  margin: 0px 0 15px;
}


.fea-contact.trending-shows{
  padding:20px 0px!important;
}
.schedule-car img{border-radius: 10px;margin: 0 auto;}
.schedule-car p {
  font-size: 14px;
  color: #000;
  padding: 10px 0 0 10px;
  font-weight: 600;
  margin: 0px;
}
.fea-contact.stydpro .slick-slider .slick-prev{ top: 40% !important;left: -10px !important;z-index: 999; }
.fea-contact.stydpro .slick-slider .slick-next{right: -10px !important; top: 40% !important; z-index: 999; }



/* .fea-contact.trending-shows .slick-slider .slick-prev{
  right: 65px!important; 
}

.fea-contact.trending-shows .slick-slider .slick-next{
  right: 20px !important; 
} */

.carousel-card{
  margin: 0px 5px 20px;
}

.carousel-card a{
  text-decoration: none;
  color: #000;
}

.carousel-card a:hover{
  color: #000;
}

.carousel-card img{
  width: 100%;
  border-radius: 6px;
  margin-bottom: 10px;
}

.carousel-card h4{
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  text-decoration: none;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 10px 0 0 0;
}
.posv{position: relative;}


.fridy{padding: 15px 0;}

.watchType{
  background: #F9ECEC;
  border-radius: 2px;
  margin: 0px 0px 0px 6px;
  padding: 0px 6px;
  line-height: 0px;
  font-size: 10px;
  color: #f00;
  display: block;
  height: auto;
  line-height: 15px;
  text-transform: uppercase;
  font-weight: 700;
}

.footer-fixed-add{
  display: none;
}

/* .fea-contact.slick-slider.slick-prev{right:0px !important} */
@media screen and (max-width: 768px) {
  .footer-fixed-add{
    display: block;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0px;

  }

  .footer-fixed-add img{
    width: 100%;
  }

  .footer-botom{
    padding: 15px 0px 50px!important;
  }
  /* .mobile-resp{display: none;} */
  .head{
      font-size: 16px;
  }
}

.topnew{
  padding:10px;
}
.topnew a {text-decoration: none;}
.topnew a img{width: 100%;border-radius: 4px;}
.news{font-weight: 700;font-size: 22px;text-transform: uppercase;}
.topnew h4{ 
  color: #2B2B2B;
  font-weight: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 16px;
  line-height: 21px;
  /* margin-bottom: 20px; */
  margin: 11px 0 0 0;
  font-weight: 500;
  font-family: 'Noto Sans Telugu', sans-serif;
}

.moed{text-decoration: none;color: #044AFC;
  text-transform: uppercase;
  text-align: end;
  font-weight: 800;
  font-size: 12px;
  text-decoration: none;display: block;}
  /* .telugunes{    -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      min-height: 45px;} */
      .topnew , .politis , .topn{background: #fff;
       
          border-radius: 3px;
          margin: 0px 0 10px 0}


          .topne{padding: 0 0 20px 0;}
.topne a , .date a {text-decoration: none;}
.topne a img{width: 100%;border-radius: 4px;}
.news{font-weight: 700;font-size: 22px;text-transform: uppercase;}
.topne a h4{ color: #2B2B2B;
    font-weight: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 13px;
    line-height: 21px;
    /* margin-bottom: 20px; */
    margin: 27px 0 0 0;
    font-weight: 600;}

/* .adddat{position: relative;} */
/* .adddat::before{
    position: absolute;
    width: 2px;
    height: 39px;
    background-color: #707070;
    display: block;
    content: '';
    top: 0px;
    right: -10px;
} */

.date a h4{    color: #2B2B2B;
    font-weight: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 16px;
    line-height: 21px;
    /* margin-bottom: 20px; */
    margin: 8px 0 0 0;
    font-weight: 500;
    font-family: 'Noto Sans Telugu', sans-serif;
}
.montrs{padding: 10px 0 30px 0;}
.topne{background: #fff;
    padding: 19px 10px;
    border-radius: 5px;}
    .colodbackfunf{   
        padding: 19px 10px;
        border-radius: 5px;
        margin: 0px !important;
      }
        .colid-in{    margin: 10px 0;
            background: #fff;
            border-radius: 5px;}
        .iconrood{padding: 0px !important
            ;}
            .fortextc h4{margin: 31px 0;    font-weight: 500;
                font-family: 'Noto Sans Telugu', sans-serif;font-size: 16px;}
        .iconrood .slick-slider .slick-arrow.slick-prev{top: 44% !important;left: 7px !important;}
        .iconrood .slick-slider .slick-arrow.slick-next{top: 44% !important;right: 7px !important;}
        .headdtoday{margin: 0px !important;}
        .pad-0d{padding: 0px !important;}
        .newsf img{margin: 0px !important;}
        .entry {background: #00021c;padding: 0px !important;}
        .entry .modal-dialog{max-width: 100%;padding: 10px ;}
        .entry .modal-dialog .modal-content{        background: #00021c;
          height: auto;
          /* max-width: 900px; */
          margin: 0 auto;}
        .tetxt{margin: 20px 0;}
        /* .entry .modal-dialog  .modal-content .modal-body{max-width: 1050px; margin: 0 auto;} */
        .entry .modal-dialog  .modal-content .modal-header{border: none !important;}
        .modal-body form input{width: 100%;}
        .for-mard{margin: 0px;width: 100%;}
        .baclod{    background: none !important;
          border: none !important;
          color: #ffffffcc !important;
     
          font-size: 34px;}
          .baclod svg{font-size: 40px;}
          .tetxt div{margin: 0px;}
          .modal-open{padding: 0px !important;}
          .modal-header .for-mard button.btn-close{display: none !important;}
          .serd{font-size: 32px;}
          /* .btn-close {
            box-sizing: content-box;
            width: 1em;
            height: 1em;
            padding: 0.25em 0.25em;
            color: #000;
            background: transparent url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23000%27%3e%3cpath d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e) center/1em auto no-repeat;
            border: 0;
            border-radius: 0.375rem;
            opacity: 0.5;
        } */
        .popvideo{    position: relative;
          width: 100%;
          height: 100%;
       }
          .popvideo-in{    position: absolute;
            width: 100%;
            background: #000000b0;
            height: 450px;
            border-radius: 10px;}
            .popvideo-in p{ color: #fff;}
            .popcontaint{    position: absolute;
              top: 40%;
              left: 32%;}
              .popcontaint p{text-align: center;font-weight: 700;}
              .butto-color:hover{color: #fff;}
            .width-for{max-width: 550px;margin: 0 auto;}
            .butto-color{background-color: #7837c5;color: #fff;text-decoration: none;padding: 10px;border-radius: 10px;width: 150px;display: block;text-align: center;}
            .heading{padding: 250px 0;}
            .heading h4{color:#fff ;font-weight: 700;font-size: 45px;}
            .backlor{background-color: #28163d;}
            .stye-font{font-size: 20px !important;}
            .ser{padding: 20px 0;}
            .margin-b{margin-bottom: 15px;}
            .stydpro{    padding: 17px 13px 17px 13px !important;}
            .bg-color{background-color: #fff !important;}
            .video-container {
              position: relative;
              width: 100%;
              height: 100%;
              padding-bottom: 56.25%; /* 16:9 aspect ratio (330 / 100 * 56.25) */
          }
          
          .video-container iframe {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 7px;
          }
          .mar-t-20{margin: 13px 0 0 0 ;}
          .nadnda{margin: 0 0 25px 0;}
          .padin-t-b-10{padding: 0 0 13px 0;}
          /* .wid-500{max-width: 730px; margin: 0 auto;} */
.wid-202{max-width: 800px;}
.showforim{padding: 10px;}
.padi-t-b-20{padding: 20px 0;}
.pad-b-t0{margin: 0px !important;}
.padin-b-t-0{padding: 0px !important;}
.seacon{text-align: center;}
.title.all.seacon {text-align: center;}
.title.all.seacon .react-tabs__tab.react-tabs__tab--selected{    background: #0c0437 !important;
  color: #fff !important;
  border: 1px solid #0c0437 !important;}
  .seacon li{    margin: 0 5px !important;}
  .fdsfsdf iframe{border-radius: 5px;}
  .fea-arrow{margin: 0 0 25px 0;}

  .text-line-limit-3 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* number of lines to show */
            line-clamp: 4; 
    -webkit-box-orient: vertical;
 }
 .syle-color{display: inline;color: #044AFC;
  
  text-align: end;
  font-weight: 800;
  font-size: 12px;}
  .font-st{    font-size: 12px !important;
    font-weight: 700;
    line-height: 18px !important;}
  /* .drop-sean select#dropdown{border: 2px solid #733d97;
    border-radius: 14px;
    padding: 7px 6px;
    background: #370558;
    color: #fff;
    font-size: 15px;
   }
      .drop-sean select#dropdown option{   font-size: 16px;padding: 10px 0;} */

      .dropdown-container {
        margin: 20px;
        font-family: Arial, sans-serif;
      }
      
      .dropdown-label {
        font-weight: bold;
        color: blue;
      }
      
      .custom-dropdown {
 


        background-color: #370558;
    border: 1px solid gray;
    border-radius: 4px;
    color: #fff;
    font-size: 15px;
    padding:  2px 4px;
   
      }
      
      .selected-option {
        font-style: italic;
      }
      .addtwostyl3{
        height: 369px;
width: 100%;}
.addtwostyl2{

              
  height: 390px;
width: 100%;
padding: 0 0 22px 0;}
.addonestyl {
  width: 100%;
height: 224px;
padding: 0 0 22px 0}
.contant-limit h4{color: #2B2B2B;
  font-weight: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 16px;
  line-height: 21px;
  /* margin-bottom: 20px; */
  margin: 11px 0 0 0;
  font-weight: 500;
  font-family: 'Noto Sans Telugu', sans-serif;padding: 0px ;}
  .styl-count{display: inline-block;
    padding: 0 10px;
    color: #370558;
    font-weight: 700;
    font-size: 20px;
    margin: 0 0px 0 -1px;}
    .vie-style-colr a{color: red !important;text-align: end !important;
      display: block !important;
      
      text-decoration: underline !important;}
      .new-list-style-class{padding: 60px 0;}
      .new-list-style-class ul {padding: 0px;}
      .new-list-style-class ul li{background-color: #fff;padding: 10px;list-style: none;margin: 0 0 20px 0;}

      .new-list-style-class ul li h4{padding: 10px 0;}
      /* body{
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    } */
    .footer-total-styl{
        margin-top: auto;
    }
   
   .epidos.ash-borde-class{border-radius: 0px !important;}
   /* .footer-total-styl{margin-top: auto;
    position: absolute;
    width: 100%;
    bottom: 0px;
    z-index: 999;} 
    body{position: relative;
      min-height: 100vh;padding-bottom: 11.3rem;} */
 

 .title.comedy li.react-tabs__tab.react-tabs__tab--selected{border: 1px solid #cd0102 !important;
  color: #cd0102 !important;}
  .title.comedy li.react-tabs__tab.react-tabs__tab--selected:focus-visible{display: none;}
  .title.comedy li.react-tabs__tab{margin: 0 20px 14px 0;border: 1px solid #fff !important}
  .respon-img-width-heig{width: 100%;height: 115px;
    object-fit: cover;}
    .pading-top{    padding: 0px 0px 11px 0px;}
    /* .custem-container{max-width: 1349px;margin: 0 auto;} */
  /* .extra.exa-mar img{margin: 10px 0 !important;} */
  .epidos-add{background-color: #fff;
    padding: 6.6px;
    border-radius: 4px;
    margin: 20px 0 !important;}
    .img-size img{width: 100%;border-radius: 5px;}
    .page-card table{}
    .page-card table tr{    border-bottom: solid 2px #4f3f3f;}
    .page-card table tr td{    margin: 0px !important;
      border: 2px solid #28163d !important;
      padding: 6px;}
    .page-card table tr td p{margin: 0px !important;text-align: center;}
    .syle-color{border: none;
      background: none;
      text-decoration: underline;}
      .oneline-style{    -webkit-line-clamp: 1 !important;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .img-marign-clas{margin: 0px !important;}
      .back-style{background: none !important;}
      .form-inputr form input:focus{border: 1px solid #ced4da !important;}
      .form-inputr form input{border: 1px solid #ced4da !important;}
      input:focus, input.form-control:focus {

        outline:none !important;
        outline-width: 0 !important;
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
    }
    .form-inputr form label.form-label{    padding: 13px 0 4px 0;
      margin: 0px;
  } 
  .pading-class{margin: 30px 0;}
  .form-inputr{padding: 60px !important;}
  
  .livtv.video-container a img{width: 100%;}
  .img-class{padding: 0px;}
  .img-class a img{width: 100%;}


  .video-container {
    position: relative;
    width: 100%;
  }
  
  .video-container::before {
    content: "";
    display: block;
     /* 16:9 aspect ratio (9 / 16 = 0.5625 or 56.25%) */
  }
  
  .video-container iframe,
  .video-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  
 .slick-track{margin: 0px !important;}
 .sum a img {
  width: 100%;
  border-radius: 8px;
}
.watchType{   margin: 3px 0px 0px 0px !important;
 }
 .erroeclass{color: red;
  font-size: 15px;    padding: 0px 0 0 3px;}
  .page-card form textarea:focus {
    border: 1px solid #ced4da !important;
}
.time-class-style{font-size: 12px;font-weight: 600;color: #8e7d7d;}
.fousclass:focus{ background: #ecf0f1 !important;}
.form-new-styling select ,.form-new-styling input ,.form-new-styling textarea{border: none;
  background: #ecf0f1;}
  .feedback-bg-clor{background-color: #28163d !important;}
  .wid-100{width: 100% !important;}
  .form-new-styling label{padding: 0 0 0 5px;}
  .lates-add{max-width: 728px;height: 90px;}

