.headtred{color: #000;
    font-weight: 700;
    font-size: 22px;
    padding: 0 10px;}
    .fea-contact .slick-slider .slick-arrow.slick-next{background: #fff;
        width: 33px;
        height: 32px;
        border-radius: 27px;line-height: 32px;text-align: center;}
        /* .fea-contact .slick-slider .slick-arrow.slick-prev{background: #fff;
            width: 33px;
            height: 32px;
            border-radius: 27px;line-height: 32px;text-align: center;} */
            .fdsf svg{ color: #000;font-Size: 17px !important;}
            
    .dskfmdskf svg{ color: #000;font-Size: 17px !important ;text-align: center;}