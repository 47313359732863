.backcolo{background-color: #fff;margin: 21px 0;border-radius: 10px;padding: 30px;}
.backcolo-in{padding: 10px 0 0 0;}
.showtime{display: flex;}
.showtime p{}
.showtime span{color: red;  overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;}
.sara{margin: 0px !important;}
.ali{color: #044AFC;font-weight: 700;padding: 10px 0;font-size: 22px;}
.showtime span{color: #DA1E25;font-size: 12px;font-weight: 700;line-height: 26px;padding: 0 10px;}
.shiw{background-color: #EBEBEB ;width: 81px;height: 29px;border-radius: 4px;font-size: 12px;text-align: center;height: 29px;line-height: 29px;}
.clic{background-color: #F5E613;color: #000;}
.watch{background-color: #370558;max-width: 312px !important;margin: 0 0 0 auto;border-radius: 6px;height: auto;}
.watch p{color: #fff;font-size:14px;margin: 0px;}
.watch p span{color: #fff;font-size:14px;font-weight: 700;}
.clic{color: #000;
    text-decoration: none;
    max-width: 112px;
    height: 40px
;
    font-size: 15px;
    display: block;
    font-weight: 700;
    border-radius: 0 4px 4px 0;
    line-height: 39px;
    text-align: center;}
/* .watch-in{padding: 10px;} */
/* .watch-in{display: flex;} */
.padd{padding: 40px;}
.video-react{border-radius: 10px;}
.video-react-controls-enabled video{border-radius: 10px;}
.videod{padding: 0 0 30px 0;}
.edio{margin: 0px !important;}
.videod .video-react .video-react-big-play-button {
    font-size: 3em;
    line-height: 49px !important;
    height: 49px !important;
    width: 54PX !important;
    display: block;
    position: absolute;
    top: 45% !important;
    left: 45% !important;
    padding: 0;
    cursor: pointer;
    opacity: 1;
    border:none !important;
    background-color: #2B333F;
    background-color: #FF0300 !important;
    border-radius: 100% !important;
    transition: all 0.4s;
}
.video-react .video-react-control-bar {

    border-radius: 0 0px 10px 9px !important
}
.videod iframe{width: 100%;height: 550px;border-radius: 15px;}
.video-react {
 background-color: none !important;}
 .watch-in{padding: 10px;}
 .telugu{padding: 0px !important;}
 .telugu .etv-telugu{background: red;
    height: 40px;
    text-align: center;
    line-height: 37px;
    border-radius: 4px 0 0 4px;}
    .videod img{width: 100%;border-radius: 10px;}
    .sara > div {padding: 0px ;}
    @media only screen and (max-width: 1100px) {
        .watch p span {
            color: #fff;
            font-size: 12px;
            font-weight: 700;
        }
        
        

    }
    @media only screen and (max-width: 450px) {
        

        .backcolo {
  
            padding: 11px !important;
        }
        .clic {
            font-size: 9px !important;
        }
            }
    @media only screen and (max-width: 991px) {
        

.clic {font-size: 13px !important;}
    }
      @media only screen and (max-width: 767px) {
        .watch {
        
            margin: 0 0 0 !important;
           
        }
        

        .clic {
        
            font-size: 9px !important;}
            .watch p span {
                color: #fff;
                font-size: 12px !important;
                font-weight: 700;
            }
            .showtime span {
             
                font-size: 9px !important;
             
            }
              
              }
