.button{border: 1px solid #dee2e6;
    color: #fff;
    text-transform: capitalize;
    font-weight: 600;
    border-radius: 4px;
    font-size: 12px;
    text-decoration: none;
    background-color: blue;
    width: 146px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    display: inline-block;
    margin: 0 0 0 12px;

}
.pad-12{padding: 0 0 6px 0;}
.weekbutton a{text-decoration: none;color: #fff;}
.weekbutton a:hover{color: #fff;}
.weekbutton {background-color: #001E6A !important;}