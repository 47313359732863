.newsLinks ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.newsLinks ul li{
    position: relative;
    padding:0px 0px 10px 10px;
    border-bottom: 1px dashed #000;
    margin-bottom: 10px;
}

.newsLinks ul li:last-child{
    border-bottom: 0px;
}

.newsLinks ul li:before{
    content: '';
    position: absolute;
    width: 5px;
    height: 5px;
    background: #000;
    border-radius: 40px;
    left: 0px;
    top: 6px;
}

.newsLinks ul li a{
    color: #2B2B2B;
    font-weight: normal;
    display: -webkit-box;
    -webkit-line-clamp:1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 16px;
    line-height: 21px;
    /* margin-bottom: 20px; */
    margin: 8px 0 0 0;
    font-weight: 500;
    text-decoration: none;
    font-family: 'Noto Sans Telugu', sans-serif;
}

.newsLinks ul li a:hover{
    color:#CD0102;
}
.poli{background-color: #fff;padding: 10px;}