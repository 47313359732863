.politis a img{width: 100%;}
.topn a {text-decoration: none;}
.topn a img{width: 100%;border-radius: 4px;}
.news{font-weight: 700;font-size: 22px;text-transform: uppercase;}
.food ul{padding: 0px;}

.politis p{font-size: 11px;margin: 0px;padding: 8px 0;font-weight: 700;}
.policits{padding: 0 30px 0 0;}
.langue{width: 5px;height: 5px;display: inline-block;background-color: #2B2B2B;border-radius: 50px;}
.facd{padding: 10px 0;}
.food ul li{list-style: none;}
