.main-slider{
    .carousel-indicators{
        justify-content: end;
        margin-right: 7%;
        button{
            width: 12px;
            height: 12px;
            border-radius: 12px;
            border-top: 0px;
            border-bottom: 0px;
            margin-right: 5px;
            margin-left: 5px;

            &.active{
                width: 40px;
                background: #ffc700;
            }
        }
    }
}

.form-select{
    font-size: smaller;
    font-weight: 700;
}

.tv-schedule{
    background: #fff;
    .schedule-list{
        position: relative;
        background: #044afc;
        padding: 45px 15px;

        &::before{
            content: '';
            position: absolute;
            width: 100px;
            height: 100%;
            background: #044afc;
            left: -100px;
            top: 0px;
        }

        h4{
            text-transform: uppercase;
            color: #fff;
            font-weight: 400;
            font-size: 22px;
        }

        p{
            color: #fff;
        }
    }
    
}
.scheduled-data{
    padding: 15px;
    .nav-tabs{
        border-bottom: 0px;
        margin-bottom: 15px;
        display: flex;

        .nav-item{
           
            border-radius: 5px;
            margin-right: 15px;

            &:first-child{
                margin-left: 0px;
            }

            .nav-link{
                border: 1px solid #dee2e6;
                padding: 0.2rem 1rem;
                color: #000;
                text-transform: capitalize;
                font-weight: 600;
                border-radius: 4px;
                font-size: 14px;

                &.active{
                    background: #044afc;
                    border-color: #044afc;
                    color: #fff;
                }
            }
        }
    }

    .slick-slider{
        position: relative;
    }

    .slick-arrow{
        position: relative;
        top: 0px;
        right: 0px;
        width: 100%;
        width: 22px;
        height: 22px;
        background: #fff;
        border-radius: 30px;
        float: right;
        box-shadow: 0px 3px 6px #00000017;


        // &:before{
        //     color: #000;
        //     opacity: inherit;
        // }
    }

    .slick-arrow.slick-next{
        top: 20px !important;
        left: -40px;
        
        // &:before{
        //      content:'\f054';
        //  font-family:"Font Awesome 5 Free";

        // }
    }


    .slick-arrow.slick-prev{
        top: 110px;
        left: -80px;
    }


}

.schedule-card{
    background: #f2f2f2;
    border-radius: 6px;
    padding: 10px;
    margin-right: 13px;
    height: 90px;

    h3{
        color: #0C0C0C;
        text-align: left;
        font-size: 12px;
        font-weight: 600;
        margin: 0px;
        display: inline-block;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
                line-clamp: 2; 
        -webkit-box-orient: vertical;
       

        span{
            font-size: 12px;
        }
    }

    p{
        font-size: 10px;
        font-weight: normal;
        margin: 0px;
    }
}
.frid{
    border-bottom: 1px solid#00000045;
}
.chanel{
    padding: 0px;
}
@media screen and (max-width: 1620px) {
    .respon-img-width-heig {
        width: 100%;
        height: 141px;
        object-fit: cover;
    }


}
@media screen and (max-width: 1600px) {
    .respon-img-width-heig {
        width: 100%;
        height: 132px;
        object-fit: cover;
    }


}
@media screen and (max-width: 1500px) {
    .respon-img-width-heig {
        width: 100%;
        height: 122px;
        object-fit: cover;
    }


}
@media screen and (max-width: 1400px) {
    


}


@media screen and (max-width: 1366px) {
    // .schedule-card{
    //     h3{
    //         width: 160px;
    //     }
    // }
    .respon-img-width-heig {
        width: 100%;
        height: 116px;
        object-fit: cover;
    }
}

@media screen and (max-width: 1199px) {
    .scheduled-data{
        .nav-tabs{
            overflow: hidden;
            overflow-x: auto;
            white-space: nowrap;
            display: inline-block;
            width: 550px;
            padding-bottom: 10px;
            margin-bottom: 0px;

            .nav-item{
                display: inline-block;
            }
        }

        .slick-arrow.slick-prev{
            top: 120px;
        }

        .slick-arrow.slick-next{
            top: 30px !important;
        }
    }

    .schedule-card{
        h3{
            width: 190px;
        }
    }

    .tv-schedule{
        .schedule-list{
            padding: 60px 15px;
            p{
                font-size: 13px;
            }
        }
    }
    .respon-img-width-heig {
        width: 100%;
        height: 85px;
        object-fit: cover;
    }
}

@media screen and (max-width: 991px) {
    .respon-img-width-heig {
        width: 100%;
        height: 91px;
        object-fit: cover;
    }


    .tv-schedule{
        .schedule-list{
            p{
                font-size: 12px;
            }
        }
    }

    .schedule-card{
        h3{
            width: 165px;
        }
    }

    .scheduled-data{
        padding: 15px 0px;
    }
}

@media screen and (max-width: 880px) {
    .schedule-card{
        h3{
            width: 150px;
        }
    }
}

@media screen and (max-width: 768px) {
      .respon-img-width-heig {
        width: 100%;
        height: 91px;
        object-fit: cover;
    }
    .main-slider{
        .carousel-indicators{
            button{
                width: 8px;
                height: 8px;
                margin-right: 3px;
                margin-left: 3px;

                &.active{
                    width: 25px;
                }
            }
        }
    }
    .tv-schedule{
        .schedule-list{
            // padding: 15px;

            p{
                margin-bottom: 0px;
            }

            h4{
                font-size: 18px;
            }
        }
    }

    .slick-slider{
        .slick-list{
            width: 100%;
        }
    }

    .scheduled-data{

        .slick-slider{
            margin-top: -18px;
        }
        .slick-arrow.slick-prev{
            top: 143px;
        }
    }

    .frid{
        border-bottom:0px;
    }
}

.timeing{
    position: absolute;
    bottom: 0px;
    right: 0px;
    width: 58px;
    height: 18px;
    background: yellow;
    background-color: rgba(255, 255, 0, 0.9);
    background-size: 100%;

    span{
        text-align: center;
        display: block;
        font-size: 12px;
        font-weight: 700;

    }
 }

 @media screen and (max-width: 767px) {
    .tv-schedule{
        .schedule-list{
            padding: 15px;
        }
    }
 }

@media screen and (max-width: 650px) {
    .scheduled-data{
       .nav-tabs{
         width: 500px;
       }
    }
}

@media screen and (max-width: 600px) {
    .schedule-card{
        h3{
            width: 190px;
        }
    }
}

@media screen and (max-width: 520px) {
    .scheduled-data{
       .nav-tabs{
         width: 420px;
       }
    }

    .schedule-card{
        h3{
            width: 150px;
        }
    }
}

@media screen and (max-width: 480px) {
    .scheduled-data{
       .nav-tabs{
         width: 360px;
       }
    }
}

@media screen and (max-width: 390px) {
    .scheduled-data{
       .nav-tabs{
         width: 300px;
       }
    }
}