@import url('https://fonts.googleapis.com/css2?family=Dhurjati&display=swap');
.latestNews{
    background: #044afc;
    padding: 5px 0px;
    color: #fff;
    padding-right: 10px;
}

.latentNews{
    padding: 5px 10px;
    border-radius: 3px;
    background: #fce404;
    white-space: nowrap;
    margin-right: 10px;
}

.latentNews p{
    color: #000;
    text-align: center;
    font-size: 22px; 
    font-weight: 400;
    margin: 0px;
    font-family: 'Dhurjati', sans-serif;
    line-height: 1;
}

.newslist{
    margin: 0;
    padding: 0;
}

.newslist li{
    display: inline-block;
    margin: 0px 20px 0px 0px;
}

.newslist li a{
    text-decoration: none;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    font-family: 'Noto Sans Telugu', sans-serif;
}

.marquee-container .overlay{
    --gradient-color: rgba(0, 76, 251, 1), rgba(0, 76, 251, 0)!important;
    --gradient-width: 50px!important;
}

@media only screen and (max-width: 1320px) {
 
}

@media only screen and (max-width: 1138px) {
    .latentNews{  margin-left: 0px;}
    .latentNews p {
      color: #000;
      text-align: center;
      font-size: 15px;
      font-weight: 400;
      margin: 0px;
      font-family: 'Dhurjati', sans-serif;
      line-height: 1;
    }
    .buto-style-clas{
      top:0px!important;
    }
}

@media only screen and (max-width: 992px) {
  .styl-count {
    font-size: 18px;

}
.mobile-divs-done{display: none;}

  
}
@media only screen and (max-width: 600px) { 


  .small-devices{display: none ;}
}

@media only screen and (max-width: 420px) {
    .latentNews p{font-size: 13px; }
}
.forsize{color: #fce404;font-size: 18px;margin: 0 5px ;}
.modal-header .headtile{font-size: 16px;}
.newpic {padding: 10px 0;}
.newpic img {width: 100%;height: 250px;border-radius: 10px;}
.headtile h4{font-weight: 700;font-size: 20px;font-family: 'Noto Sans Telugu', sans-serif;}
.modal-body p{text-align: justify;font-family: 'Noto Sans Telugu', sans-serif;font-size: 16px;}
.buto-style-clas{position: relative;top:3px; left:10px; right:-10px;}
.buto-style-clas a{color: #fff;    text-decoration: none;
    padding: 4px 4px;
    font-size: 11px;
    background: #fce404;
    color: red;
    font-weight: 800;

    
    background: #fce404;color: red;font-weight: 700;}
    .backcolo-clas p{
        position: absolute;
        width: 0;
        height: 0;
        border-top: 12px solid transparent;
        border-left: 9px solid #fce404;
        border-bottom: 12px solid transparent;
        top: 1px;
        right: -9px;
      }
      .newslist li:last-child {margin-right: 366px !important;}


     /* news  */

    .marquee-container {
        width: 100%;
        overflow: hidden;
      }
      
      .marquee-text {
        display: inline-block;
        white-space: nowrap;
        animation: marquee 18s linear infinite;
        -webkit-animation: marquee 18s linear infinite; 
        width: 100%;
      }
      
      .marquee-container:hover .marquee-text {
        animation-play-state: paused;
      }
    
    
      @keyframes marquee {
        0% {
            transform: translateX(100%);
          }
        
          15% {
            transform: translateX(30);
          }
        
          85% {
            transform: translateX(10);
          }
        
          100% {
            transform: translateX(-100%);
          }
        }
.popup-footer{justify-content: center !important;}

.custom-marquee p {
  text-decoration: none;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  font-family: 'Noto Sans Telugu', sans-serif;
  margin: 0px;
  cursor: pointer;
}

.custom-marquee{
  padding-left: 88%;
}


@media (max-width: 768px){
  .custom-marquee{
    padding-left: 70%;
  }
}
