
.feature{
    background-color: #fff;
    border-radius: 5px;
    margin: 0px 0 15px;
}
.head{
    font-size: 20px;
    color: #044afc;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;
    display: inline;
}
.fea-contact{padding: 16px 13px 16px 13px;}
.fea-contact.trending-shows{
    padding:20px 0px!important;
}
.schedule-car img{border-radius: 10px;margin: 0 auto;}
.schedule-car p {
    font-size: 14px;
    color: #000;
    padding: 10px 0 0 10px;
    font-weight: 600;
    margin: 0px;
}




.fea-contact.trending-shows .slick-slider .slick-prev{
    right: 65px!important; 
    
    
}

.fea-contact.trending-shows .slick-slider .slick-next{
    right: 20px !important; 
}

.carousel-card{
    margin: 0px 5px;
}

.carousel-card a{
    text-decoration: none;
    color: #000;
}

.carousel-card a:hover{
    color: #000;
}

.carousel-card img{
    width: 100%;
    border-radius: 6px;
 
    margin: 8px 0 0 0 !important;
}

.carousel-card h4{
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    text-decoration: none;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* .fea-contact.slick-slider.slick-prev{right:0px !important} */
@media screen and (max-width: 768px) {
    .head{
        font-size: 16px;
    }
}
.fea-contact .slick-slider .slick-arrow.slick-next{background: #f2f2f2 !important;
    width: 27px !important;
    height: 27px !important;
    border-radius: 27px;line-height: 32px;text-align: center;line-height: 27px !important;right: -8px !important;
    top: 37% !important;}
    .fea-contact .slick-slider .slick-arrow.slick-prev{background: #f2f2f2 !important;
        width: 27px !important;
        height: 27px !important;
        border-radius: 27px;line-height: 32px;text-align: center;right: 46px !important; top: 37% !important ;left: -10px !important;line-height: 27px !important;z-index: 9999;}
        .fdsf svg{ color: #000;font-Size: 17px !important;}
        
.dskfmdskf svg{ color: #000;font-Size: 17px !important ;text-align: center;}
.iconcame p{ border: 2px solid #fff;
    width: 28px;
    height: 28px;
    line-height: 23px;
    background-color: #ff0000f7;
    text-align: center;
    border-radius: 52px;
    /* display: block; */
    display: inline-block;
}
.iconcame p svg{color: #fff;font-size: 12px;}
.extra{position: relative;}
.jfhdsahfuo{position: relative;}

/* .sfdsf {background:#ffffff;width:32px;height:32px;line-height:20px;border-radius:20px;display:inline-block; } */