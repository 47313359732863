


.fea-contact.trending-shows{
    padding:20px 0px!important;
}
.schedule-car img{border-radius: 10px;margin: 0 auto;}
.schedule-car p {
    font-size: 14px;
    color: #000;
    padding: 10px 0 0 10px;
    font-weight: 600;
    margin: 0px;
}



.fea-contact.trending-shows .slick-slider .slick-prev{
    right: 65px!important; 
}

.fea-contact.trending-shows .slick-slider .slick-next{
    right: 20px !important; 
}

.carousel-card{
    margin: 0px 5px;
}

.carousel-card a{
    text-decoration: none;
    color: #000;
}

.carousel-card a:hover{
    color: #000;
}

.carousel-card img{
    width: 100%;
    border-radius: 6px;
    margin-bottom: 10px;
}

.carousel-card h4{
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    text-decoration: none;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.colod{background-color: #ECECEC;padding: 14px 0;}
.schudul h4{

    text-align: left;
    font: normal normal bold 22px/30px Open Sans;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    margin: 0px;
    padding: 7px 0;
    }


/* .fea-contact.slick-slider.slick-prev{right:0px !important} */
@media screen and (max-width: 768px) {
    .head{
        font-size: 16px;
    }
}