.topnew{
    padding:10px;
}
.topnew a {text-decoration: none;}
.topnew a img{width: 100%;border-radius: 4px;}
.news{font-weight: 700;font-size: 22px;text-transform: uppercase;}
.topnew h4{ 
    color: #2B2B2B;
    font-weight: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 16px;
    line-height: 21px;
    /* margin-bottom: 20px; */
    margin: 11px 0 0 0;
    font-weight: 500;
    font-family: 'Noto Sans Telugu', sans-serif;
}

.moed{text-decoration: none;color: #044AFC;
    text-transform: uppercase;
    text-align: end;
    font-weight: 800;
    font-size: 12px;
    text-decoration: none;display: block;}
    /* .telugunes{    -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        min-height: 45px;} */
        .topnew , .politis , .topn{background: #fff;
         
            border-radius: 3px;
            margin: 0px 0 10px 0}