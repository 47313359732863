
.sfdsf svg{ color: #000;font-Size: 13px !important;}
        
.dskfmds svg{ color: #000;font-Size: 17px !important ;text-align: center;}
.fea-contact .slick-slider .slick-arrow.slick-next{background: #fff;
    width: 33px;
    height: 32px;
    border-radius: 27px;line-height: 32px;text-align: center;top: 32% !important;}
    .fea-contact .slick-slider .slick-arrow.slick-prev{background: #fff;
        width: 33px;
        height: 32px;
        border-radius: 27px;line-height: 32px;text-align: center;top: 32% !important;}
        .sfdsf{text-align: center;line-height: 22px;}