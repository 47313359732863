.showforimg img {border-radius: 5px; }
.showforimg{padding: 10PX 0;}
.shiwd{padding: 30px 0;}
.showforimg a img{
    height: 220px;
    display: block;
    margin: 0px auto;
}

@media only screen and (max-width: 460px) {
    .showforimg a img{
        width: 100%;
    }
}
.showforim a img{width: 100%;}
.showforim a{text-decoration: none;}
.showforim a h4{    font-size: 16px;
    color: #2B2B2B;
    padding: 10px 0;
    font-family: 'Noto Sans Telugu', sans-serif;
    font-weight: 500;
}
.showforim{background: #fff;
    padding: 0px;
    border-radius: 7px;
    text-align: center;
    margin: 0 0 30px 0;}

.topnew h4 {
    color: #2B2B2B;
    font-weight: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 16px;
    line-height: 21px;
    /* margin-bottom: 20px; */
    margin: 11px 0 0 0;
    font-weight: 500;
    font-family: 'Noto Sans Telugu', sans-serif;
    }