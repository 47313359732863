.top{background-color:#F2F2F2;padding: 20px 0;}
.intereting{padding: 30px 0;border-top: 1px dashed #707070;margin: 21px 0;}
.poli{    background: #fff;
    padding: 10px;}
    .max-wid-100{max-width: 100%;text-align: center;}
    .noise img {
        border-radius: 5px;
    }

    @media only screen and (max-width: 992px) {
        .newsf img{
            width: 100%;
            height: auto;
            object-fit: cover;
      
      }
      .responive-img{display: none;}
    
    }
