.nadnda{padding:20px 0 ;}
.gere{color: #000;font-weight: 700;font-size: 22px;padding: 0 10px;}
.headtred{color: #000;
    font-weight: 700;
    font-size: 22px;
    padding: 0 10px;}
    /* .fea-contact .slick-slider .slick-arrow.slick-next{background: #fff;
        width: 33px;
        height: 32px;
        border-radius: 27px;line-height: 32px;text-align: center; top: -41px !important;} */
        /* .fea-contact .slick-slider .slick-arrow.slick-prev{background: #fff;
            width: 33px;
            height: 32px;
            top: -41px !important;
            border-radius: 27px;line-height: 32px;text-align: center;}
            .fdsf svg{ color: #000;font-Size: 17px !important;} */
            
    .dskfmdskf svg{ color: #000;font-Size: 17px !important ;text-align: center;}
    .headtred{margin: 0 0 20px 0;}
    /* .carousel-card a h4{font-size: 11px;
        color: #2B2B2B;
        font-family: 'LikhithTlg045Unicode';
        font-weight: 700;
     ;} */
    /* .fea-gender{padding: 0px !important;margin: 10px 0;} */
    .extrasty a h4{    color: #2B2B2B;
        font-weight: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-size: 16px;
        line-height: 21px;
        /* margin-bottom: 20px; */
        margin: 8px 0 0 0;
        font-weight: 500;
        font-family: 'Noto Sans Telugu', sans-serif;
    }
        .fea-gender{padding: 0px !important;}
        .nadnda{background: #fff;
            padding: 10px;}