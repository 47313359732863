.main-menu-bg{
    background: #CD0102;
    padding: 2px 0px!important;

    .nav-link{
        text-transform: uppercase;
        color: #fff;
        font-size: 16px;
        padding: 0.4rem 1.5rem!important;
        font-weight: 400;

        &:hover{
            color: #fff;
        }
    }

    .nav-link.active{
        color: #fff!important;
    }

    .dropdown{
        .nav-link{
            color: #fff!important;
        }
    }

    .dropdown-menu{
        border: 0px;
        border-radius: 0px;
        border-radius: 0px 0px 5px 5px;
        width: 260px;
        box-shadow: 0px 2px 3px rgba(92, 92, 92, 0.3);
        margin: 0px;
        padding: 0px;

        .dropdown-item{
            text-transform: capitalize;
            font-size: 14px;

            &:active{
                background:#e40000!important;
            }
        }

        a{
            display: flex;
            align-items: center;
            border-bottom: 1px dotted rgba(92, 92, 92, 0.2);
            padding:6px 15px;
            font-weight: 700;
            font-size: 13px;
            color: #000;

            span{
                display: inline-block;
                max-width: 200px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            &:last-child{
                border-bottom: 0px;
            }

            &:hover{
                background: rgba(92, 92, 92, 0.1);
            }

            img{
                width: 35px;
                height: 35px;
                object-fit: fill;
                border-radius: 50px;
                margin-right: 10px;
            }
        }
    }
}

.search-bar{

    .form-control{
        height: 35px;
    }
    .btn{
        background: #fff;
        border: 1px solid #ced4da;
        border-left:0px;
        height: 35px;

        &:hover, &:active{
            background: #fff!important;
            
            border: 1px solid #ced4da!important;
            border-left:0px!important;
        }

        svg{
            fill: #8a8a8a;
            margin-top: -5px;
        }
    }
}
.barnav a{color: #fff;}
.forpading{margin: 0px;}
.forpading  a{text-decoration: none;text-transform: uppercase;}
.forpading  a:hover{color: #fff;}
.vie-style-colr a{color: red;}