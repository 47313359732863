.colof{background-color: #ECECEC;}
.allshiw{color: #000;
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    margin: 0px;text-transform: uppercase;}
    .all{text-align: end;}
.all li.react-tabs__tab.react-tabs__tab--selected{text-transform: uppercase;color: #CD0102 !important;border: 1px solid #CD0102 !important;background-color: #fff !important;}
.all{margin: 0px !important;margin: 0px;}
                   
.shoed{padding: 10px 0;}

.SubpageTitleBar .title{
    margin: 0px;
    padding: 0px;
}
.SubpageTitleBar .title li{
    text-transform: uppercase;
}

@media only screen and (max-width: 991px) {
    .SubpageTitleBar{
        background: #CD0102;
    }

    .SubpageTitleBar .allshiw{
        color: #fff;
    }

    .SubpageTitleBar .title li{
        color: #fff;
    }
}