.showforimg img {border-radius: 5px; }
.showforimg{padding: 10PX 0;}
.shiwd{padding: 30px 0;}
.showforimg a img{
    height: 220px;
    display: block;
    margin: 0px auto;
    width: 100%;
    height: auto;
}

@media only screen and (max-width: 460px) {
    .showforimg a img{
        width: 100%;
    }
}