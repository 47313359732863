.logo-bar{
    padding:10px 0px;
    width: 100%;
}

.logo{
   height: 86px;
}

.app-links{
    margin: 0;
    padding:0;
}

.app-links li{
    display: inline-block;
}

/* .app-links li:first-child{
    margin-right: 10px;
}

.app-links li:first-child{
    margin-right: 10px;
} */

.app-links li img{
    height: 37px;
}


@media screen and (max-width: 1340px) {
    .app-links li img{
        height: 32px;
    }
}

@media screen and (max-width: 1085px) {
    .app-links li img{
        height: 30px;
    }
}

@media screen and (max-width: 991px) {
    .logo-bar{
        padding: 5px 0px;
    }

    .logo{
        height: 45px;
    }
}