
.feature{
    background-color: #fff;
    border-radius: 5px;
    margin: 0px 0 15px;
}


.fea-contact.trending-shows{
    padding:20px 0px!important;
}
.schedule-car img{border-radius: 10px;margin: 0 auto;}
.schedule-car p {
    font-size: 14px;
    color: #000;
    padding: 10px 0 0 10px;
    font-weight: 600;
    margin: 0px;
}
.fea-contact.stydpro .slick-slider .slick-prev{ top: 40% !important;left: -10px !important;z-index: 999; }
.fea-contact.stydpro .slick-slider .slick-next{right: -10px !important; top: 40% !important; z-index: 999; }
 


/* .fea-contact.trending-shows .slick-slider .slick-prev{
    right: 65px!important; 
}

.fea-contact.trending-shows .slick-slider .slick-next{
    right: 20px !important; 
} */

.carousel-card{
    margin: 0px 5px 20px;
}

.carousel-card a{
    text-decoration: none;
    color: #000;
}

.carousel-card a:hover{
    color: #000;
}

.carousel-card img{
    width: 100%;
    border-radius: 6px;
    margin-bottom: 10px;
}

.carousel-card h4{
    font-size: 14px;
    font-weight: 700;
    line-height: 19px;
    text-decoration: none;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 10px 0 0 0;
}
.posv{position: relative;}


.fridy{padding: 15px 0;}

.watchType{
    background: #F9ECEC;
    border-radius: 2px;
    margin: 0px 0px 0px 6px;
    padding: 0px 6px;
    line-height: 0px;
    font-size: 12px;
    color: #f00;
    display: block;
    height: auto;
    line-height: 15px;
    text-transform: uppercase;
    font-weight: 700;
}

/* .fea-contact.slick-slider.slick-prev{right:0px !important} */
@media screen and (max-width: 768px) {
    .head{
        font-size: 16px;
    }

    .watchType {
       
        font-size: 6px;}
}