.topn a {text-decoration: none;}
.topn a img{width: 100%;border-radius: 4px;}
.news{font-weight: 700;font-size: 22px;text-transform: uppercase;}
.food ul{padding: 0px;}
 .polici{border-bottom: 1px dashed #707070;}
.polici p{  font-size: 11px;
    color: #2B2B2B;
    line-height: 20px;
    font-family: 'LikhithTlg045Unicode';
    font-weight: 700;
    padding: 10px 0;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
 
  }
.policits{padding: 0 30px 0 0;}
.langue{width: 5px;height: 5px;display: inline-block;background-color: #2B2B2B;border-radius: 50px;}
.topn.facd{padding: 10px 0;}
.food ul li{list-style: none;}

.hebusin{padding: 10px 0 30px 0;}
.noise img{width: 100%;}
.noise{padding: 4px 20px ;}
.busoin{background-color: #fff;padding: 10px;}