.footer-botom{background: #ECECEC ;padding: 15px 0;}
.techink{text-align: center;}
.techink ul li{
    display: inline-block;
    padding: 0px 15px;
    border-right: 1px solid;
    line-height: 10px;}
.techink ul li  a{text-decoration: none;font-size: 14px;text-align:center;color: #000000;} 
.enadu{ margin: 0px; font-size: 14px;}
.csr{border: none !important;}

@media screen and (max-width: 768px) {
    .techink ul{
        margin: 0px 0px 10px 0px;
        padding: 0px 0px 10px 0px;
        border-bottom: 1px dotted rgba(0, 0, 0, 0.3);
    }

    .techink ul li{
        padding: 0px 12px;
    }
}