.cms-content{
    padding: 50px 0px;
    position: relative;
    background: #ecf0f1;
}

.page-card{
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
}

.cms-content h1{
    font-size: 30px;
    margin-bottom: 30px;
    text-transform: uppercase;
    font-weight: 800;
    color: #044afc;
}

.cms-content h3{
    font-size: 16px;
    font-weight: 700;
}

.cms-content h4{
    color: #CD0102;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
}

.cms-content ul{
    margin: 0px 0px 20px 0px;
    padding: 0px;

}

.cms-content ul li{
    padding-left: 20px;
    position: relative;
    list-style-type: none;
    margin-bottom: 10px;
}

.cms-content ul li:before{
    content: "";
    width: 10px;
    height: 10px;
    border: 4px solid #CD0102;
    background: #fff;
    border-radius: 20px;
    left: 0;
    margin-right: 5px;
    position: absolute;
    top: 7px;
}
.address-block{
    background: #fff;
    padding: 25px;
    border-radius: 10px;
    margin: 0px 0px 25px 0px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
}

.chanelLogo{
    width: 80px;
    height: 80px;
    background: #0c0514;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0px auto 10px;
}

.chanelLogo img{
    height: 60px;
}

.address-block h5{
    font-size: 22px;
    text-align: center;
    font-weight: 800;
    text-transform: uppercase;
    color: #222;
    margin: 0px 0px 20px 0px;
}

.address-block p{
    margin-bottom: 8px;
    font-weight: 600;
}

.address-block svg{
    margin-right: 6px;
    fill: #CD0102;
    font-size: 18px;
}