.topne{padding: 0 0 20px 0;}
.topne a , .date a {text-decoration: none;}
.topne a img{width: 100%;border-radius: 4px;}
.news{font-weight: 700;font-size: 22px;text-transform: uppercase;}
.topne a h4{ color: #2B2B2B;
    font-weight: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 13px;
    line-height: 21px;
    /* margin-bottom: 20px; */
    margin: 27px 0 0 0;
    font-weight: 600;}

/* .adddat{position: relative;} */
/* .adddat::before{
    position: absolute;
    width: 2px;
    height: 39px;
    background-color: #707070;
    display: block;
    content: '';
    top: 0px;
    right: -10px;
} */

.date a h4{    color: #2B2B2B;
    font-weight: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 16px;
    line-height: 21px;
    /* margin-bottom: 20px; */
    margin: 8px 0 0 0;
    font-weight: 500;
    font-family: 'Noto Sans Telugu', sans-serif;
}
.montrs{padding: 10px 0 30px 0;}
.topne{background: #fff;
    padding: 19px 10px;
    border-radius: 5px;}
    .colodbackfunf{   
        padding: 19px 10px;
        border-radius: 5px;
        margin: 0px !important;
      }
        .colid-in{    margin: 10px 0;
            background: #fff;
            border-radius: 5px;}
        .iconrood{padding: 0px !important
            ;}
            .fortextc h4{margin: 31px 0;    font-weight: 500;
                font-family: 'Noto Sans Telugu', sans-serif;font-size: 16px;}
        .iconrood .slick-slider .slick-arrow.slick-prev{top: 44% !important;left: 7px !important;}
        .iconrood .slick-slider .slick-arrow.slick-next{top: 44% !important;right: 7px !important;}
        .headdtoday{margin: 0px !important;}
        .pad-0d{padding: 0px !important;}
        .newsf img{margin: 0px !important;}
