.maind{background-color: #f2f2f2;}
.featur {
    background-color: #fff;
    border-radius: 5px;
    margin: 0px 0 15px;
}


.fea-contact.fea-arrow .slick-slider .slick-arrow.slick-next{background: #f2f2f2 !important;
    width: 27px !important;
    height: 27px !important;
    border-radius: 27px;
    line-height: 32px;
    text-align: center;
    line-height: 27px !important;
    right: -8px !important;
    top: 49% !important;}
    .fea-contact.fea-arrow .slick-slider .slick-arrow.slick-prev {
        background: #f2f2f2 !important;
        width: 27px !important;
        height: 27px !important;
        border-radius: 27px;
        line-height: 32px;
        text-align: center;
        right: 46px !important;
        top: 49% !important;
        left: -10px !important;
        line-height: 27px !important;
        z-index: 9999;
    }

.fea-contact.trending-shows .slick-slider.slick-initialized .slick-arrow.slick-prev{    left: -12px !important;
    top: 47% !important;
    z-index: 9998;
    background: #fff !important;}
.fea-contact.trending-shows .slick-slider.slick-initialized .slick-arrow.slick-next{right: -12px !important;top: 47% !important;
    z-index: 9998;background: #fff !important;}
    .hedin{margin: 0px !important;}
    .fea-contact.trending-shows.pading{padding: 10px 0px 0 0 !important;}
    .more {
        color: #044AFC;
        text-transform: uppercase;
        text-align: end;
        font-weight: 800;
        font-size: 12px;
        text-decoration: none;
    }
    .slick-slider button{
        display: none !important;
    }
    .fea-pading{padding: 20px 10px !important;}
    .extra img{margin: 0px !important;}
.daf {
    /* max-width: 1432px; */
    /* margin: 0 auto; */
    padding: 41px 0;
}


@media screen and (max-width: 768px) {
    .daf{
        padding: 25px 0px;
    }
}