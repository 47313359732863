.yout{width: 100% !important; height: auto !important; }
.yout video{ border-radius: 5px;}
.live{    font-weight: 700;
    font-size: 22px;
    text-transform: uppercase;
}
.livtv{background: #ffff;
    border-radius: 5px;
    /* padding: 20px; */
    margin: 0 0px 10px 0px;}
    .pad-30{padding: 12px 20px;
        background-color: #fff;
        border-radius: 5px;}