.footermain{background: #2E5FFC 0% 0% no-repeat padding-box;padding: 39px 0 39px 0;;}
.padig{padding: 0 10px;}
.conte{color: #ffffff;text-decoration: none;}
.cont{text-align: end;}
.cont li{display: inline-block;
    padding: 0 10px;
    color: #fff;
}
.soci a{ 
    background: rgba(0, 0, 0, 0.2);
    width: 45px;
    height: 45px;
    display: block;
    color: #fff;
    border-radius: 50px;text-align: center;line-height: 45px;
}
.soci a:hover{
    color: #fff;
    background: rgba(0, 0, 0, 0.9);
}
.cont{margin: 0px;}

@media screen and (max-width: 768px) {
    .displa-mobile-none{display: none;}
    .footermain{
        padding: 25px 0 14px 0;
    }

    .app-links{
        margin-bottom: 25px;
    }

    .app-links li:first-child{
        display: block;
        margin-bottom: 6px;
    }

    .app-links li img{
        height: 40px;
    }

    .cont{
        padding: 0px;
        margin: 0px;
        text-align: center;
    }

    .cont li:first-child{
        display: block;
        margin-bottom: 6px;
        padding: 0px;
    }

    .cont li:nth-child(2){
        padding-left: 0px;
    }
}