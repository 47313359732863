.shope .slick-slider.slick-initialized .slick-arrow.slick-next{top: 4% !important;right: 14% !important;}
.shope .slick-slider.slick-initialized .slick-arrow.slick-prev{top: 4% !important;right: 28% !important;z-index: 999;left: auto !important;}
.addmiddle{background-color: #fff;border-radius: 10px;}
.addmiddle-in{padding: 62px 20px 20px 20px;}
.addmiddle-in p{margin: 0px;font-size: 14px;line-height: 19px;font-weight: 600;}
.addmiddle-in a img{border-radius: 10px;}


.arrow-left {background:#ffffff;width:32px;height:32px;line-height:20px;border-radius:20px;display:inline-block; box-shadow:0px 3px 5px rgba(0, 0, 0, 0.1) }
.arrow-right{background:"#ffffff";width:32px;height:32px;line-height:20px;border-radius:20px;display:inline-block; box-shadow:0px 3px 5px rgba(0, 0, 0, 0.1)}
.arrow-right-show {  font-size: 14px;margin:9px 0px 0px 10px;color: #000;}