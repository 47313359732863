.chanel-categories{
    width: 100%;
    background: #28163D;
    padding: 0px!important;
}

.chanel-categories .nav-link{
    text-transform: uppercase;
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
    border-right: 1px solid rgba(0, 0, 0, 0.3);
    padding:0.5rem 0.8rem!important;
}

.chanel-categories .nav-link img{
    height: 35px;
    margin-right: 1px;
}

.chanel-categories .nav-link.active,
.chanel-categories .nav-link:hover{
    background: rgba(0, 0, 0, 0.3);
    color: #fff!important;
}

.chanel-categories .nav-item.dropdown .nav-link{
    padding: 1rem 0.8rem 1rem 2rem!important;
    border-right: 0px;
}

.chanel-categories .nav-item.dropdown .nav-link:focus,
.chanel-categories .nav-item.dropdown .nav-link:focus-visible{
  color: #fff;
}

 .chanel-categories .nav-item.dropdown,
.chanel-categories .nav-item.dropdown .nav-link{
    position: relative;
}

.chanel-categories .nav-item.dropdown:before{
    content:'';
    position: absolute;
    width: 10px;
    height: 10px;
    left: 15px;
    top: 22px;
    background: #e40000;
    border-radius: 30px; 
    z-index: 1;
}

.chanel-categories .nav-item.dropdown .nav-link:before{
    content:'';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 30px; 
    left: 15px;
    top: 22px;
    background: #e40000;
    animation: ripple 1.5s ease-out infinite;
} 

.chanel-categories .dropdown-toggle.show.nav-link:before,
.chanel-categories .nav-item.show.dropdown:before{
    background:#fff;
}

@keyframes ripple {
    from {
      opacity: 1;
      transform: scale(0);
    }
    to {
      opacity: 0;
      transform: scale(3);
    }
  }

.chanel-categories .dropdown-menu{
    width: 270px;
    border-radius: 0px;
    right: 0px;
    left: inherit!important;
    border: 0px;
    padding: 10px;
    background: #e8e8e8;
    box-shadow: 0px 2px 3px rgba(92, 92, 92, 0.2);
    grid-template-columns: repeat(2, 1fr);
    border-radius: 0px 0px 10px 10px;
  }

.chanel-categories .dropdown-menu .dropdown-item{
    width: 80px;
    height: 80px;
    display: inline-flex;
    background: #fff;
    border-radius: 6px;
    margin-bottom: 5px;
    align-items: center;
    position: relative;
    justify-content: space-between;
}

.chanel-categories .dropdown-menu .dropdown-item:nth-child(2),
.chanel-categories .dropdown-menu .dropdown-item:nth-child(5){
    margin-left: 5px;
    margin-right: 5px;
}

.chanel-categories .dropdown-menu .dropdown-item img{
    height: 50px;
    width: 100%;
    position: relative;
}

.chanel-categories .dropdown-menu .dropdown-item .live-tag{
    position: absolute;
    top: 5px;
    right: 5px;
    background: #e40000;
    color: #fff;
    padding: 0px 6px 0px 8px;
    font-size: 8px;
    text-transform: uppercase;
    border-radius: 3px;
    font-weight: 500;
    letter-spacing: 0.2px;
}

.chanel-categories .dropdown-menu .dropdown-item .live-tag::before{
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    background: #fff;
    border-radius: 10px;
    top: 4px;
    left: 2px;
}

.offcanvas-footer{
    display: none;
}

/* .chanel-categories .dropdown-menu .dropdown-item:nth-of-type(even){
    float: right;
} */

.mobile-menu .accordion-item .accordion-body .showed a{
  background: transparent;
  padding: 5px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.mobile-menu .accordion-item .accordion-body .showed a:last-child{
  border-bottom: 0px;
}

.chanel-categories .dropdown-toggle.show.nav-link{
    background: #e40000;
  }

  @media screen and (max-width: 1300px) {
    .chanel-categories .nav-link img{
        height: 32px
    }

    .chanel-categories .nav-link{
        font-size: 12px;
    }
  }

  @media screen and (max-width: 1199px) {
    .chanel-categories .nav-link img{
        display: none;
    }
  }

  @media screen and (max-width: 991px) {

    .desktop-menu{
        display: none!important;
    }

    .offcanvas.MobleSideNav{
        width: 300px!important;
        background: #28163D!important;
        z-index: 99999;
      }

    .MobleSideNav .offcanvas-header{
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        padding: 10px 16px;
    }

    .MobleSideNav .offcanvas-header .btn-close{
        color: #fff!important;
        opacity: 0.7;
        background: transparent url(close.svg) center/1em auto no-repeat;
    }

    .MobleSideNav .offcanvas-header .btn-close:focus{
        border: 0px;
        box-shadow: inherit;
    }

    .offcanvas-footer{
        display: block;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        position: fixed;
        width: 300px;
        bottom: 0px;
        left: 0px;
        text-align: center;
        color: #fff;
        padding: 7px 15px;
        background: #28163D;
        z-index: 99;
    }

    .offcanvas-footer .title{
      font-size: 13px;
      font-weight: 500;
      color: rgba(225, 225, 225, 0.7);
      text-transform: uppercase;
    }

    .offcanvas-footer .links a{
      color: rgba(225, 225, 225, 0.7);
      font-size: 17px;
      margin-right: 10px;
    }

    .offcanvas-footer .links a:hover{
      color:#fff;
    }

    .offcanvas-footer .links a:last-child{
      margin-right: 0px;
      font-size: 20px;
    }
    .MobleSideNav .offcanvas-body{
        padding-bottom: 50px;
    }
    
    .MobleSideNav .nav-link,
    .mobile-menu a{
        font-size: 13px;
        font-weight: 400;
        padding: 0.5rem 0.7rem;
        text-transform: uppercase;
        color: #fff;
        background: rgba(0, 0, 0, 0.3);
        letter-spacing: 0.6px;
        border-radius: 0.375rem;
        margin-bottom: 10px;
    }

    .mobile-menu a{
      display: block;
      text-decoration: none;
      color: #fff!important;
    }

    .MobleSideNav .accordion-item .nav-link,
    .mobile-menu .accordion-item .dropdown-item{
        background: transparent;
        margin-bottom:0;
        font-size: 13px;
        padding: 0.5rem 0.5rem;
        font-weight: 400;
        text-transform: capitalize;
    }
    
      .MobleSideNav .nav-link:hover,
      .MobleSideNav .active,
      .mobile-menu .dropdown-item{
        color: #fff!important;
      }
    
      .MobleSideNav .nav-link img{
        height: 25px;
        margin-right: 6px;
      }

      .mobile-menu .accordion-item{
        background: transparent;
        border: 0px;
        margin-bottom: 10px;
      }

      .mobile-menu .accordion-item button{
        font-size: 13px;
        font-weight: 400;
        padding: 0.5rem 0.7rem;
        text-transform: uppercase;
        color: #fff;
        background: rgba(0, 0, 0, 0.3);
        letter-spacing: 0.6px;
        border-radius: 0.375rem;
      }

      .mobile-menu .accordion-item button:after{
        width: 16px;
        background: transparent url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
      }

      .mobile-menu .accordion-item:first-of-type .accordion-button{
        border-radius: 0.375rem;
      }

      .mobile-menu .accordion-header button:focus{
        border: 0px;
        box-shadow: inherit;
      }

      .mobile-menu .accordion-item .accordion-body{
        padding: 5px;
      }

    .mobile-menu .live-list .dropdown-item{
        width: 100px;
        height: 70px;
        display: inline-block;
        justify-content: center;
        background: rgba(0, 0, 0, 0.2);
        margin: 5px;
        border-radius: 4px;
        padding: 8px;
        position: relative;
    }

    .mobile-menu .live-list .dropdown-item:hover{
        background: rgba(225, 225, 225, 0.2);
    }

      .mobile-menu .live-list .dropdown-item img{
        height: 50px;
        display: flex;
        margin: auto;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
      }

    .mobile-menu .live-list .dropdown-item .live-tag{
        position: absolute;
        top: 5px;
        right: 5px;
        background: #e40000;
        color: #fff;
        font-size: 9px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 1px 6px;
        border-radius: 2px;
        line-height: 1;
    }

    .mobile-menu  .accordion-button:not(.collapsed){
        box-shadow: inherit;
    }

    .sub-accordion{
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        padding-top: 15px;
        margin-top: 15px;
    }

    .chanel-categories{
      position: absolute!important;
      top: 55px;
      width: 70px;
      background: transparent;
    }

    .chanel-categories .navbar-toggler{
      border: 0;
      padding: 0px;
    }

    .chanel-categories .navbar-toggler:focus{
      border: 0px;
      box-shadow: inherit;
    }

  }